<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        class="d-flex-center justify-content-start gap-3 mb-1"
      >
        <label
          class="mb-0"
          for="enable-payment-password"
        >
          {{ $t('myAccount.paymentPassword.title') }}
        </label>
        <b-form-checkbox
          id="enable-payment-password"
          :checked="enablePaymentPassword"
          class="custom-control-success"
          name="check-button"
          switch
          inline
          @click.native.prevent="handleInputEnablePaymentPassword"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col
        cols="12"
        class="d-flex-between"
      >
        <b-button
          v-if="enablePaymentPassword"
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="changePaymentPassword"
        >
          {{ $t('myAccount.paymentPassword.changePassword') }}
        </b-button>

        <b-button
          v-if="canResetPaymentPassword"
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          variant="outline-secondary"
          @click="resetPaymentPassword"
        >
          {{ $t('myAccount.paymentPassword.reset') }}
        </b-button>
      </b-col>
    </b-row>

  </b-card>
</template>

<script>
import {
  BButton, BRow, BCol, BCard, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { computed } from '@vue/composition-api'
import Vue from 'vue'

import store from '@/store'
import { apiEmployee } from '@/api'
import IAmPaymentPassword from '@/components/IAmPaymentPassword.vue'
import i18n from '@/libs/i18n'

import { validatorPaymentPassword } from '@core/utils/validations/validators'

import useToast from '@useToast'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BCard,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    const enablePaymentPassword = computed(() => store.getters['userStore/getMeEnablePaymentPassword'])
    const getMeDataId = computed(() => store.getters['userStore/getMeDataId'])

    const { toastSuccess, toastError } = useToast()
    async function handleInputEnablePaymentPassword() {
      // ANCHOR turn off payment password
      if (enablePaymentPassword.value) {
        const paymentPassword = await Vue.swal({
          title: this.$t('myAccount.paymentPassword.confirmTurnOff'),
          html: '<div id="i-am-payment-password"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'off' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            if (!validatorPaymentPassword(pwd)) {
              Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
            } else { resolve(pwd) }
          }),
          showCancelButton: true,
          confirmButtonText: this.$t('confirmation'),
          cancelButtonText: this.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          allowOutsideClick: false,
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
        if (paymentPassword.value) {
          this.$bvModal.show('modal-api-loading')
          const payload = {
            enablePaymentPassword: false,
            paymentPassword: paymentPassword.value,
          }
          apiEmployee.updateEmployee(getMeDataId.value, payload)
            .then(async () => {
              await store.dispatch('userStore/fetchMeData')
              toastSuccess({
                title: 'messagesList.success',
                content: 'myAccount.paymentPassword.updateSuccess',
              })
            })
            .catch(() => {
              toastError({
                title: 'messagesList.error',
                content: 'myAccount.paymentPassword.updateError',
              })
            })
            .finally(() => {
              this.$bvModal.hide('modal-api-loading')
            })
        }
      } else {
        // ANCHOR turn on payment password
        const paymentPassword = await Vue.swal({
          title: this.$t('myAccount.paymentPassword.title'),
          html: '<div id="i-am-payment-password"></div>',
          didOpen: () => {
            new Vue({
              render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'on' } }),
              i18n,
            }).$mount('#i-am-payment-password')
          },
          focusConfirm: false,
          preConfirm: () => new Promise(resolve => {
            const pwd = document.getElementById('swal-input-payment-password-1').value
            const rePwd = document.getElementById('swal-input-payment-password-2').value
            if (!validatorPaymentPassword(pwd) || !validatorPaymentPassword(rePwd)) {
              Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
            } else if (pwd !== rePwd) {
              Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.differentRePassword'))
            } else if (pwd === rePwd) resolve(pwd)
            resolve(false)
          }),
          showCancelButton: true,
          confirmButtonText: this.$t('save'),
          cancelButtonText: this.$t('cancel'),
          buttonsStyling: true,
          customClass: {
            confirmButton: 'btn btn-gradient',
            cancelButton: 'btn btn-danger mr-1',
          },
          allowOutsideClick: false,
          reverseButtons: true,
        })
        if (paymentPassword.isDenied || paymentPassword.isDismissed) {
          return
        }
        if (paymentPassword.value) {
          this.$bvModal.show('modal-api-loading')
          const payload = {
            enablePaymentPassword: true,
            paymentPassword: paymentPassword.value,
          }
          apiEmployee.updateEmployee(getMeDataId.value, payload)
            .then(async () => {
              await store.dispatch('userStore/fetchMeData')
              toastSuccess({
                title: 'messagesList.success',
                content: 'myAccount.paymentPassword.updateSuccess',
              })
            })
            .catch(() => {
              toastError({
                title: 'messagesList.error',
                content: 'myAccount.paymentPassword.updateError',
              })
            })
            .finally(() => {
              this.$bvModal.hide('modal-api-loading')
            })
        }
      }
    }

    // F2 F3 đang ko tự reset được
    function resetPaymentPassword() {
      this.$bvModal
        .msgBoxConfirm(this.$t('myAccount.paymentPassword.resetConfirm'), {
          title: this.$t('modal.confirm'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('modal.yes'),
          cancelTitle: this.$t('modal.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$bvModal.show('modal-api-loading')
            apiEmployee.resetPaymentPassword(getMeDataId.value)
              .then(async res => {
                await store.dispatch('userStore/fetchMeData')
                const { username, newPassword } = res
                Vue.swal({
                  title: this.$t('messagesList.success'),
                  html: `
                  <div class="text-20px font-weight-bolder">
                    ${this.$t('myAccount.paymentPassword.updateSuccess')}
                    <div class="text-16px">
                      Mật khẩu thanh toán mặc định của tài khoản <span class='text-uppercase text-20px font-weight-bolder'>${username}</span> là <code class='px-50 text-20px font-weight-bolder'>${newPassword}</code>
                    </div>
                  </div>`,
                  icon: 'success',
                  showCancelButton: true,
                  showConfirmButton: false,
                  allowEscapeKey: true,
                  allowOutsideClick: false,
                  cancelButtonText: this.$t('close'),
                  customClass: {
                    title: 'text-20px font-weight-bolder text-success',
                    cancelButton: 'btn btn-flat-danger',
                  },
                  buttonsStyling: false,
                })
              })
              .catch(() => {
                toastError({
                  title: 'messagesList.error',
                  content: 'myAccount.paymentPassword.updateError',
                })
              })
              .finally(() => {
                this.$bvModal.hide('modal-api-loading')
              })
          }
        })
    }

    async function changePaymentPassword() {
      const paymentPassword = await Vue.swal({
        title: this.$t('myAccount.paymentPassword.title'),
        html: '<div id="i-am-payment-password"></div>',
        didOpen: () => {
          new Vue({
            render: h => h(IAmPaymentPassword, { props: { typePaymentPassword: 'change' } }),
            i18n,
          }).$mount('#i-am-payment-password')
        },
        focusConfirm: false,
        preConfirm: () => new Promise(resolve => {
          const oldPwd = document.getElementById('swal-input-payment-password-old').value
          const newPwd = document.getElementById('swal-input-payment-password-1').value
          const rePwd = document.getElementById('swal-input-payment-password-2').value
          if (!validatorPaymentPassword(oldPwd) || !validatorPaymentPassword(newPwd) || !validatorPaymentPassword(rePwd)) {
            Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.errorInput'))
          } else if (newPwd !== rePwd) {
            Vue.swal.showValidationMessage(this.$t('myAccount.paymentPassword.differentRePassword'))
          } else if (newPwd === rePwd) resolve({ oldPwd, newPwd })
          resolve(false)
        }),
        showCancelButton: true,
        confirmButtonText: this.$t('save'),
        cancelButtonText: this.$t('cancel'),
        buttonsStyling: true,
        customClass: {
          confirmButton: 'btn btn-gradient',
          cancelButton: 'btn btn-danger mr-1',
        },
        allowOutsideClick: false,
        reverseButtons: true,
      })
      if (paymentPassword.isDenied || paymentPassword.isDismissed) {
        return
      }
      if (paymentPassword.value) {
        this.$bvModal.show('modal-api-loading')
        const payload = {
          oldPaymentPassword: paymentPassword.value.oldPwd,
          newPaymentPassword: paymentPassword.value.newPwd,
        }
        apiEmployee.changePaymentPassword(payload)
          .then(async () => {
            await store.dispatch('userStore/fetchMeData')
            toastSuccess({
              title: 'messagesList.success',
              content: 'myAccount.paymentPassword.updateSuccess',
            })
          })
          .catch(() => {
            toastError({
              title: 'messagesList.error',
              content: 'myAccount.paymentPassword.updateError',
            })
          })
          .finally(() => {
            this.$bvModal.hide('modal-api-loading')
          })
      }
    }

    const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    const canResetPaymentPassword = computed(() => enablePaymentPassword.value && isRoleF1.value)

    return {
      enablePaymentPassword,
      canResetPaymentPassword,
      resetPaymentPassword,
      handleInputEnablePaymentPassword,
      changePaymentPassword,
    }
  },
}
</script>
